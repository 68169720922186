import { withStyles } from "@material-ui/core/styles";


export const GlobalCss = withStyles({
         // @global is handled by jss-plugin-global.
         "@global": {
           // You should target [class*="MuiButton-root"] instead if you nest themes.

           ".MuiButton-root": {
             fontSize: "1rem",
           },

           ".MuiGrid-root": {
             padding: "0px 2px",
           },
           ".MuiOutlinedInput-root": {
             borderRadius: "6px",
           },
           ".MuiSvgIcon-root": {
             right: "3px",
           },
           ".MuiOutlinedInput-input": {
             padding: "15px 8px !important",
           },
           ".MuiInputLabel-root": {
             transform: "translate(6px, 16px) scale(1)",
           },

           ".MuiInputLabel-outlined": {
             transform: "translate(6px, 16px) scale(0.88) ",
           },

           ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
             "border-color": "#70c000",
           },
           ".MuiFormLabel-root.Mui-focused": {
             color: "black",
           },

           ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
             transform: "translate(14px, -6px) scale(0.75)",
           },
           "#HeatingUnitCost": {
             textAlign: "center",
             paddingLeft: "-3px",
           },
           "#YearlyHeatingCost": {
             textAlign: "center",
             paddingLeft: "-3px",
           },
           ".MuiPaper-root::-webkit-scrollbar": {
             display: "none",
           },
           ".MuiPaper-root": {
             "margin-bottom": "20px",
           },

           ":root": {
             "--ion-color-primary": "black",
             ionColorPrimaryRgb: "16,220,96",
             ionColorPrimaryContrast: "#ffffff",
             ionColorPrimaryContrastRgb: "255,255,255",
             ionColorPrimaryShade: "#0ec254",
             ionColorPrimaryTint: "#28e070",
           },
           "ion-header": {
             position: "fixed",
             "max-width": "800px",
           },
           "ion-footer": {
             position: "fixed",
             bottom: "0",
             "max-width": "800px",
           },

           "#headerToolbar": {
             //  "padding-left": "0px",
             //  "padding-right": "0px",
             transform: "scale(1.1)",
             "font-weight": "700",
             "font-family":
               "Gotham Medium,M Hei PRC W45,M Hei HK W42,M Hei HK W40,HelveticaNeue-Regular,Helvetica Neue Regular,Helvetica Neue,Helvetica,Arial,sans-serif",
             "margin-top": "-5px",
           },
           "#footerTitle": {
             "text-align": "center",
             transform: "scale(0.90)",
             "margin-top": "-18px",
             color: "white",
             "padding-left": "0px",
             "padding-right": "0px",
           },
           ".textContent": {
             "min-height": "180px",
             display: "flex",
             "align-items": "center",
             "justify-content": "center",
           },
           ".centerItSeelf": {
             display: "flex",
             alignItems: "center",
             justifyContent: "center",
           },
           ".marginBottom": {
             marginBottom: "20px",
           },
           ".noPaddingTop": {
             paddingTop: "2",
           },
           "#NameFieldModal": {
             textAlign: "center",
           },
           "#EmailFieldModal": {
             textAlign: "center",
           },
           "#PostalCodeFieldModal": {
             textAlign: "center",
           },
           ".red": {
             color: "red",
           },
           ".MuiCircularProgress-root:focus": {
             outline: "none",
           },
           ".MuiCardContent-root": {
             width: "100%",
           },
           ".FacebookButton": {
             "&:focus": {
               outline: "none !important",
             },
           },
           ".MuiBadge-anchorOriginTopRightRectangle": {
             right: "16px",
             cursor: "pointer",
             height: "25px",
             width: "25px",
             "border-radius": "25px",
             "font-size": "1rem",
             "background-color": "lightskyblue",
           },
         },
       })(() => null);

