//npm start
//npm run-script build
//npm build
//node deploy

//aws cloudfront create-invalidation --distribution-id ESA8Q0UX0JD7X --paths "/*"
import React, { useState, useEffect } from 'react';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonPage,
  IonButton,
  IonIcon,
  IonButtons,
} from "@ionic/react";
import { refreshOutline, closeOutline } from "ionicons/icons";


import {
  Element,
  scroller
} from "react-scroll";
 

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from "@material-ui/core/Badge";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";


import unitTypeArray from './Data/unitType.json';
import HeatPumpModelsArray from './Data/heatPumpModels.json'

import Validator from "validatorjs";
import axios from "axios"
import ip from "ip"
import { FacebookShareButton } from "react-share";
import FacebookIcon from "./facebookShare.png";

import { useQueryParams, StringParam } from "use-query-params";



// Import Ionic 5 styles
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

import {GlobalCss} from "./globalCss.js";

const themeColor1 = "lightskyblue";

function App() {


                const [{ name: qName, email: qEmail, postalCode: qPostalCode }] = useQueryParams({
                  name: StringParam,
                  email: StringParam,
                  postalCode: StringParam
                });

                 const inputRef = React.useRef(null);
                 let ss = window.innerHeight - 70 + "px";
                 const [heatingUnitType, setHeatingUnitType] = useState("");
  
                 const [heatingUnitPrice, setHeatingUnitPrice] = useState(-2);
                 const [heatingUnitString, setHeatingUnitString] = useState("");
                 const [yearlyHeatingCost, setYearlyHeatingCost] = useState("");
                 const [yearlyHeatingCostString, setYearlyHeatingCostString] = useState("");

                 const [systemEfficiencyValue, setSystemEfficienyValue] = useState("");


                 const [heatingSelectorValue, setHeatingSelectorValue] = useState("");
                 const [textFielType1, setTextFielType1] = useState("text");
                 const [textFielType2, setTextFielType2] = useState("text");

                 const [efficiencyRangeArray, setEfficiencyRangeArray] = useState([]);
                 const [heatPumpModelSelector, setHeatPumpModelSelector] = useState("");
                 const [heatPumpModel, setHeatPumpModel] = useState("");
                 const [heatPumpName, setHeatPumpName] = useState("");

                  const[areaCoverageRange, setAreaCoverageRange] = useState([]);
                 const [areaCoverageValue, setAreaCoverageValue] = useState("");
                 const [heatPumpHSPF, setHeatPumpHSPF] = useState(null);
                 const [heatPumpEnergyCost, setHeatPumpEnergyCost] = useState(null);
                const [result1, setResult1] = useState("");

                const [co2EmissionPerMBtu, setCo2EmissionPerMBtu] = useState("");
                const [btuPerUnit, setBtuPerUnit] = useState(null);
                const [savedTonsCo2, setSavedTonsCo2] = useState(" ");
                const [plantedTree, setPlantedTree] = useState(" ")

            
                const [cardDisplay1, setCardDisplay1] = useState("none");
                const [cardDisplay2, setCardDisplay2] = useState("none");
                const [modalState, setModalState] = useState(false);
                const [buttonResultDisplay, setButtonResultDisplay] = useState("flex")
                const [nameFieldModal, setNameFieldModal] = useState(qName);
                const [emailFieldModal, setEmailFieldModal] = useState(qEmail);
                const [emailFieldValidator, setEmailFieldValidator] = useState(" ");
                const [postalCodeFieldModal, setPostalCodeFieldModal] = useState(qPostalCode);
                const [postalCodeFieldValidator, setPostalCodeFieldValidator] = useState(" ");
                const [modalButtonAbled, setModalButtonAbled] = useState(true);
                const [postalCodeValidatorBoolean, setPostalCodeValidatorBoolean] = useState(false);
                const [nameFieldValidationBoolean, setNameFieldValidationBoolean] = useState(false);
                const [emailFieldValidatorBoolean, setEmailFieldValidatorBoolean] = useState(false);
                const [spinnerDisplay, setSpinnerDisplay] = useState("none")
                const [helperInvisibleYearlyBilling, setHelperInvisibleYearlyBilling] = useState(true);
                const [helperModalYearlyBilling, setHelperModalYearlyBilling] = useState(false);
                const [lowestBillValue, setLowestBillValue] = useState(100);
                const [highestBillValue, setHighestBillValue] = useState(550);
                const [billValueAnswer, setBillValueAnswer ] = useState(2880);

                const [filledLogic, setFilledLogic] = useState(true);

                const handleChangeLowestBill = (e) =>{
                  const lbv = e.target.value
                  setLowestBillValue(lbv);
                  const answer = (highestBillValue - lbv) * 6.4;
                  try{setBillValueAnswer(answer>500?answer:"")}
                  catch{}

                }
                 const handleChangeHighestBill = (e) => {
                   const hbv = e.target.value;
                   setHighestBillValue(hbv);
                   const answer = (hbv - lowestBillValue) * 6.4;
                   try{setBillValueAnswer(answer > 500 ? answer : "");}
                   catch{}
                 };

              

                function formatNumber(num) {
                  return num
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                }

                var resultNumberHeatingUnit = yearlyHeatingCost / heatingUnitPrice;
                var resultNeededHeatingCapacity =(resultNumberHeatingUnit * btuPerUnit * systemEfficiencyValue) / 100;
                var resultMultiplicator = heatPumpHSPF /1.15/3.412;
                var resultHeatCostBtu = heatPumpEnergyCost / 3412 / resultMultiplicator;
                var resultHeatPumpNeededBtu = (resultNeededHeatingCapacity * areaCoverageValue) / 100;
                var resultCoverageCurrentHeatingCost = (yearlyHeatingCost * yearlyHeatingCost) / 100;
                var resultSupplementedHeatingCost = yearlyHeatingCost * (100.001-areaCoverageValue) /100;
                var resultHeatPumpHeatingCost = (resultHeatPumpNeededBtu * resultHeatCostBtu);
                var resultSaving10Years = (yearlyHeatingCost - resultHeatPumpHeatingCost - resultSupplementedHeatingCost ) * 10;
                var resultSavingPercentage = resultSaving10Years/yearlyHeatingCost*10;


const keyboardEvent = () => {
  inputRef.current.handleClick(); //Trigger click
};

const scrollToElement1 = () => {
  scroller.scrollTo("scrollToElement", {
    duration: 1200,
    delay: 300,
    smooth: "easeInOutQuad",
    offset: -100,
    containerId: "scroll-container"
  });
};



                useEffect(() => {
                 
                 
                  if (result1 === "Your sessions has expired! Refresh to continue..."){
                    return}
                  else{
                  setResult1Text();
                  environmentalBenefitCalculation();
                    } 

                  setInterval(function() {
                    setResult1("Your sessions has expired! Refresh to continue...");
                   setCardDisplay2("none");
                  }, 600000);

                  
                  //check if field are filled to enable button
                  if ( heatingSelectorValue !== "" && heatPumpModelSelector !== "" && postalCodeFieldModal !== "" ) {
                    setFilledLogic(false);
                  } else {
                    setFilledLogic(true);
                  }
                  //---------

                  //cjeck if modal field are filled to enable button
                  
                  if ( emailFieldModal !== "" && nameFieldModal !== "" ) {
                    setModalButtonAbled(false);
                  } else {
                    setModalButtonAbled(true);
                  }
                });

                const HandleClickButton = () => {
                setModalState(true);
                
                };
                const handleClickCloseModal = () =>{
                  setModalState(false);
                };



                 const handleChangeHeatingSelector = event => {
                  //  setResult1("");
                   setHeatingSelectorValue(event.target.value);

                   var i = unitTypeArray.findIndex(
                     x => x.heatingSystemType === event.target.value
                   );
                   var v = unitTypeArray[i].initialUnitPrice;
                   var u = unitTypeArray[i].unitType;
                   var hc = unitTypeArray[i].initialHeatingCost;
                   var ie = unitTypeArray[i].initialEfficiency;
                   var era = unitTypeArray[i].efficiencyRange;
                   var bpu = unitTypeArray[i].btuPerUnit;
                   var cepm = unitTypeArray[i].co2EmissionPerMBtu;

                   setHeatingUnitPrice(v);
                   setHeatingUnitType(u);
                   UnitStringSetter(v, u);
                  setYearlyHeatingCost(hc);
                   YearlyHeatingCostStringSetter(hc);
                   setSystemEfficienyValue(ie);
                   setEfficiencyRangeArray(era);
                   setBtuPerUnit(bpu);
                   setCo2EmissionPerMBtu(cepm);

                    if (event.target.value === "Electric") {
                      setHelperInvisibleYearlyBilling(false);
                    } else {
                      setHelperInvisibleYearlyBilling(true);
                    }

                 };

                 const handleFocusHeatingSelector =()=>{
                  //  setResult1("");
                 }
                 
                 const UnitStringSetter = (value, unitType) => {
                   setHeatingUnitString(
                     value > -1 ? "$" + value + "/" + unitType + "  " : ""
                   );
                 };

                 const handleFocusFieldHeatingUnitCost = () => {
                  //  setResult1("");
                   setTextFielType1("number");
                   setHeatingUnitString(heatingUnitPrice);
                 };

                 const handleChangeHeatingUnitCost = event => {
                   
                   setHeatingUnitString(event.target.value);
                 };

                 const handleBlurHeatingUnitCost = event => {
                   
                   setTextFielType1("text");
                   var v = event.target.value;
                   if(v < 0.1){
                      v = 0.1;
                   }
                   setHeatingUnitPrice(v);
                   UnitStringSetter(v, heatingUnitType);
                 };



                 const YearlyHeatingCostStringSetter = (value) => {
                   setYearlyHeatingCostString(value > -1 ? "$" + value : "");
                 };

                 const handleFocusYearlyHeatingCost = () => {
                  //  setResult1("");
                   setTextFielType2("number");
                   setYearlyHeatingCostString(yearlyHeatingCost);
                 };
                 const handleChangeYearlyHeatingCost = event => {
                   setYearlyHeatingCostString(event.target.value);
                 };
                 const handleBlurYearlyHeatingCost = event => {
                   var v = event.target.value;
                   setTextFielType2("text");
                   setYearlyHeatingCost(v);
                   YearlyHeatingCostStringSetter(v);
                 };


                 const handleChangeEfficiencySelector = event =>{
                   
                   setSystemEfficienyValue(event.target.value)
                 };
                 const handleFocusEfficiencySelector = ()=>{
                  setResult1("");
                 }


                  const handleChangeHeatPumpSelector = event =>{
                    
                    var v = event.target.value;
                    var i = HeatPumpModelsArray.findIndex(x => x.heatPumpModel === v);
                    setHeatPumpModelSelector(v);

                    if (areaCoverageValue === ""){setAreaCoverageValue(HeatPumpModelsArray[i].initialSuperficieCoverage);} 

                    setHeatPumpHSPF(HeatPumpModelsArray[i].hspf4);
                    setHeatPumpEnergyCost(HeatPumpModelsArray[i].initialEnergyCost);
                    setAreaCoverageRange(HeatPumpModelsArray[i].areaCoverageRange);
                    setHeatPumpName(HeatPumpModelsArray[i].HeatPumpName);
                    setHeatPumpModel(HeatPumpModelsArray[i].heatPumpModel);

                  };
                  const handleFocusHeatPumpSelector = ()=>{
                    // setResult1("");
                  }

                  const handleChangeAreaCoverage = event =>{
                    setAreaCoverageValue(event.target.value)
                    
                  }
                  const handleFocusAreaCoverage = ()=>{
                    // setResult1("");
                  }

                  const handleClickRefresherButton = ()=>{
                    
                    window.location.reload(true);
                  }

                 
                  const setResult1Text = ()=>{
                     setResult1(
                       "Based on your current heating cost and a " +
                         areaCoverageValue +
                         "% area coverage, when properly sized, this Heat Pump Model has the Potential to save you $" +
                         formatNumber(Math.round(resultSaving10Years)) +
                         " over a 10 years period. It represents a " +
                         Math.round(resultSavingPercentage) +
                         "% Saving!"
                     );
                  }
               
                  let dataToSend = {
                    name: nameFieldModal,
                    email: emailFieldModal,
                    postalCode: postalCodeFieldModal,
                    ipAddress: ip.address(),
                    unitType: heatPumpModelSelector
                  };
               
                  const HandleClickButtonModal = async () => {
                    setSpinnerDisplay("block");
                    const apiUrl = "https://nl8aphweyk.execute-api.ca-central-1.amazonaws.com/dev/"
                    
                    let response = await axios.post(apiUrl, dataToSend);
                    
                    //console.log(response.data.emailApproval);
                    if (response.data.visitExceded === false) {
                      if (response.data.emailApproval === true) {
                        environmentalBenefitCalculation();
                        setButtonResultDisplay("none");
                        setResult1Text();
                        setCardDisplay1("flex");
                        setCardDisplay2("flex");

                        setModalState(false);
                        setSpinnerDisplay("none");
                        scrollToElement1();
                      } else {
                        setEmailFieldValidator("Invalid email");
                        setSpinnerDisplay("none");
                      }
                    } else {
                      setEmailFieldValidator( "Sorry you've reach the maximum amount of visit for this email... Please come back in few days!" );
                      setSpinnerDisplay("none");
                    }

                  };

                  const handleChangeNameFieldModal = (e)=>{
                    let text = e.target.value;
                    setNameFieldModal(text);
                    let boolean = text.length > 0 ? true : false;
                    setNameFieldValidationBoolean(boolean);
                    //emailFieldValidatorBoolean && boolean ? setModalButtonAbled(false) : setModalButtonAbled(true);

                  };
                  const handleChangeEmailFieldModal = e => {
                          setEmailFieldModal(e.target.value);
                          //email validation
                          let rules = {email: "required|email"};
                          let data = {email: e.target.value};
                          let validation = new Validator(data, rules);
                          let evs = validation.passes() === true ? "  " : "Invalid email";
                          setEmailFieldValidatorBoolean(validation.passes());
                          setEmailFieldValidator(evs);
                          //validation.passes() && nameFieldValidationBoolean ? setModalButtonAbled(false) : setModalButtonAbled(true);
                        };

                  const handleChangePostalCodeFieldModal = e => {
                    setPostalCodeFieldModal(e.target.value);
                    //postal code validation
                          let rules = {postalCode: ["required", 'regex:/^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d ?$/'] };
                          let data = {postalCode: e.target.value};
                          let validation = new Validator(data, rules);
                          let psvs = validation.passes() === true ? "  " : "invalid postal code";
                          setPostalCodeFieldValidator(psvs);

                          setPostalCodeValidatorBoolean(validation.passes());
                  };

                    
                
               

                  const environmentalBenefitCalculation = ()=>{
                    //Data sourced from
                    //https://www.toolkit.bc.ca/sites/default/files/2014_best_practices_methodology_for_quantifying_greenhouse_gas_emissions%20%281%29.pdf
                    //Electricity 0.000707 tons/Kwh   =   0.207209848 tons/MBtu 
                    //Electricity 0.000495 tons/Kwh   =   0.145076202 tons/MBtu
                    //Propane                         =   0.0695 tons/MBtu
                    //Oil                             =   0.0805 tons/MBtu
                    //Natural Gas                     =   0.0585 tons/MBtu  
                    //wood                            =   0.0347 tons/MBtu

                    //Tree Offset/                    =   0.24 tons/Tree*10year
                  
                    const ElectricityCO2Emission = 0.145076202;
                    const treeCo2Offset10Years = 0.24;

                    var currentSystemC02Emission = co2EmissionPerMBtu / (systemEfficiencyValue/100);
                    var selectedHeatPumpCo2Emission = ElectricityCO2Emission / resultMultiplicator;
                    var yearlySavedEmissionTons = (currentSystemC02Emission-selectedHeatPumpCo2Emission)*resultNeededHeatingCapacity*(areaCoverageValue/100)/1000000
                    var conversionToTreeS = yearlySavedEmissionTons *10/ treeCo2Offset10Years;

                    setPlantedTree(conversionToTreeS.toFixed(0));
                    setSavedTonsCo2(yearlySavedEmissionTons.toFixed(2));
                    if (cardDisplay1 === "flex"){
                      
                     yearlySavedEmissionTons < 0.2 ? setCardDisplay2("none"):setCardDisplay2("flex");
                    } 
                  };


                  const facebookHandleClick = async () => {
                    const apiUrl =
                      "https://q9dznzv0ah.execute-api.ca-central-1.amazonaws.com/dev/";

                    await axios.post(apiUrl, dataToSend);
                  };

                  

                 return (
                   <>
                     <IonPage>
                       <GlobalCss />
                       {/* <IonContent> */}
                       <Element
                         className="element"
                         id="scroll-container"
                         style={{
                           position: "relative",
                           overflow: "scroll",
                           "overflow-x": "hidden",
                         }}
                       >
                         <CssBaseline />

                         <Container
                           disableGutters
                           /*maxWidth="sm"*/ style={{
                             maxWidth: "800px",
                             minHeight: ss,
                             position: "relative",
                           }}
                         >
                           <IonHeader>
                             <IonToolbar
                               color="primary"
                               style={{ height: "50px" }}
                             >
                               <IonTitle
                                 size="small"
                                 className="ion-text-center"
                                 id="headerToolbar"
                               >
                                 HEAT PUMP SAVING CALCULATOR
                               </IonTitle>

                               <IonButtons slot="end">
                                 <IonButton
                                   onClick={handleClickRefresherButton}
                                 >
                                   <IonIcon
                                     icon={refreshOutline}
                                     size="large"
                                   ></IonIcon>
                                 </IonButton>
                               </IonButtons>
                             </IonToolbar>
                             <Box
                               style={{
                                 width: "100%",
                                 height: "15px",
                                 "background-color": themeColor1,
                               }}
                             ></Box>
                           </IonHeader>
                           <br />
                           <Grid
                             container
                             justify="center"
                             xs={12}
                             style={{
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                               marginTop: "65px",
                             }}
                           ></Grid>
                           <Container
                             style={{
                               paddingLeft: "2px",
                               paddingRight: "2px",
                               paddingTop: "0px",
                             }}
                           >
                             <Typography
                               className="centerItSeelf"
                               variant="body2"
                               component="h2"
                               style={{
                                 color: "#70c000",
                                 "text-align": "center",
                                 "font-size": "1.25rem",
                                 padding: "20px",
                               }}
                             >
                               Fill in the form to learn how much you can
                               save!
                             </Typography>
                             <Grid container justify="center" xs={12}></Grid>

                             <Grid container>
                               <Grid item xs={12}>
                                 <FormControl fullWidth variant="outlined">
                                   <InputLabel>
                                     Current Heating System
                                   </InputLabel>
                                   <Select
                                     style={{ textAlign: "center" }}
                                     id="HeatingTypeSelector"
                                     value={heatingSelectorValue}
                                     onChange={handleChangeHeatingSelector}
                                     onFocus={handleFocusHeatingSelector}
                                     labelWidth="177"
                                   >
                                     {unitTypeArray.map((list) => (
                                       <MenuItem
                                         key={list.heatingSystemType}
                                         value={list.heatingSystemType}
                                       >
                                         {list.heatingSystemType}
                                       </MenuItem>
                                     ))}
                                   </Select>
                                 </FormControl>
                               </Grid>
                               <Grid xs={12}>
                                 <br />
                               </Grid>

                               <Grid item xs={6}>
                                 <TextField
                                   style={{ textAlign: "center" }}
                                   type={textFielType1}
                                   fullWidth
                                   id="HeatingUnitCost"
                                   label="Cost/Unit"
                                   variant="outlined"
                                   value={heatingUnitString}
                                   onFocus={handleFocusFieldHeatingUnitCost}
                                   onChange={handleChangeHeatingUnitCost}
                                   onBlur={handleBlurHeatingUnitCost}
                                   inputProps={{
                                     min: 0.1,
                                     max: 350,
                                     step: 0.01,
                                   }}
                                 />
                               </Grid>

                               <Grid item xs={6}>
                                 <Badge
                                   style={{ width: "100%" }}
                                   color="secondary"
                                   badgeContent="?"
                                   invisible={helperInvisibleYearlyBilling}
                                   onClick={() =>
                                     setHelperModalYearlyBilling(true)
                                   }
                                 >
                                   <TextField
                                     type={textFielType2}
                                     fullWidth
                                     id="YearlyHeatingCost"
                                     label="Yearly Heating Cost"
                                     variant="outlined"
                                     value={yearlyHeatingCostString}
                                     onFocus={handleFocusYearlyHeatingCost}
                                     onChange={handleChangeYearlyHeatingCost}
                                     onBlur={handleBlurYearlyHeatingCost}
                                     inputProps={{
                                       min: 500,
                                       max: 15000,
                                       step: 10,
                                     }}
                                   />
                                 </Badge>
                               </Grid>

                               <Grid xs={12}>
                                 <br />
                               </Grid>
                               {/* <Grid item xs={6}>
                               <FormControl fullWidth variant="outlined">
                                 <InputLabel>System Efficiency</InputLabel>
                                 <Select style={{ textAlign:"center" }}
                                   id="systemEfficiencySelector"
                                   value={systemEfficiencyValue}
                                   labelWidth="132"
                                   onChange={handleChangeEfficiencySelector}
                                   onFocus={handleFocusEfficiencySelector}
                                 >
                                   {efficiencyRangeArray.map(list => (
                                     <MenuItem key={list} value={list}>
                                       {list}%
                                     </MenuItem>
                                   ))}
                                 </Select>
                               </FormControl>
                             </Grid> */}

                               <Grid xs={12}>
                                 <br />
                                 <br />
                               </Grid>

                               <Grid item xs={12}>
                                 <FormControl fullWidth variant="outlined">
                                   <InputLabel>Heat Pump Model</InputLabel>
                                   <Select
                                     id="HeatPumpTypeSelector"
                                     value={heatPumpModelSelector}
                                     onChange={handleChangeHeatPumpSelector}
                                     onFocus={handleFocusHeatPumpSelector}
                                     labelWidth="132"
                                   >
                                     {HeatPumpModelsArray.map((list) => (
                                       <MenuItem
                                         key={list.heatPumpModel}
                                         value={list.heatPumpModel}
                                       >
                                         {list.HeatPumpName +
                                           " - " +
                                           list.heatPumpModel}
                                       </MenuItem>
                                     ))}
                                   </Select>
                                 </FormControl>
                               </Grid>
                               <Grid xs={12}>
                                 <br />
                               </Grid>
                               <Grid item xs={6}>
                                 <FormControl fullWidth variant="outlined">
                                   <InputLabel>Area Coverage</InputLabel>
                                   <Select
                                     style={{ textAlign: "center" }}
                                     id="AreaCoverageSelector"
                                     value={areaCoverageValue}
                                     onChange={handleChangeAreaCoverage}
                                     onFocus={handleFocusAreaCoverage}
                                     labelWidth="110"
                                   >
                                     {areaCoverageRange.map((list) => (
                                       <MenuItem key={list} value={list}>
                                         {list}%
                                       </MenuItem>
                                     ))}
                                   </Select>
                                 </FormControl>
                               </Grid>
                               <Grid item xs={6}>
                                 <TextField
                                   required
                                   style={{ textAlign: "center" }}
                                   type="postal code"
                                   fullWidth
                                   id="PostalCodeFieldModal"
                                   label="Postal Code"
                                   variant="outlined"
                                   value={postalCodeFieldModal}
                                   onFocus=""
                                   onChange={handleChangePostalCodeFieldModal}
                                   onBlur=""
                                   inputProps={{ maxlenght: "6" }}
                                 />
                                 <Typography
                                   className="centerItSeelf"
                                   variant="body2"
                                   component="h5"
                                   style={{ height: "20px", color: "red" }}
                                 >
                                   {postalCodeFieldValidator}
                                 </Typography>
                               </Grid>
                               <Grid item xs={12}></Grid>
                               <Grid item xs={12}></Grid>
                               <Grid item xs={12}></Grid>
                               <Grid item xs={12}></Grid>
                               <Grid item xs={12}></Grid>

                               <Grid>
                                 <br />
                               </Grid>
                             </Grid>
                             <Grid
                               container
                               justify="center"
                               xs={12}
                               style={{ display: buttonResultDisplay }}
                             >
                               <IonButton
                                 disabled={filledLogic}
                                 style={{
                                   backgroundColor: themeColor1,
                                   borderRadius: "100px",
                                 }}
                                 color="primary"
                                 shape="round"
                                 expand="full"
                                 size="large"
                                 fill="outline"
                                 onClick={HandleClickButton}
                               >
                                 Get Results
                               </IonButton>
                             </Grid>
                             <Grid>
                               <br />
                             </Grid>
                             <Element name="scrollToElement">
                               <Card
                                 style={{
                                   display: cardDisplay1,
                                   margin: "6px",
                                   "background-color": themeColor1,
                                 }}
                               >
                                 <CardContent>
                                   <Card
                                     style={{
                                       margin: "5px",
                                       backgroundColor: "transparent",
                                     }}
                                   >
                                     <Typography
                                       className="textContent"
                                       variant="h2"
                                       component="h2"
                                       align="center"
                                       style={{
                                         lineHeight: "60px",
                                         fontSize: "2rem",
                                         fontWeight: "700",
                                         minHeight: "20px",
                                         width: "100%",
                                       }}
                                     >
                                       POTENTIAL SAVING
                                     </Typography>
                                   </Card>
                                   <Typography
                                     className="textContent"
                                     variant="h5"
                                     component="h5"
                                     align="center"
                                     style={{ lineHeight: "45px" }}
                                   >
                                     {result1}
                                   </Typography>
                                 </CardContent>
                               </Card>
                             </Element>

                             <Grid>
                               <br />

                               <br />
                             </Grid>

                             <Card
                               style={{
                                 display: cardDisplay2,
                                 margin: "6px",
                                 "background-color": "palegreen",
                               }}
                             >
                               <CardContent>
                                 <Card
                                   style={{
                                     margin: "5px",
                                     backgroundColor: "transparent",
                                   }}
                                 >
                                   <Typography
                                     className="textContent"
                                     variant="h2"
                                     component="h2"
                                     align="center"
                                     style={{
                                       lineHeight: "60px",
                                       fontSize: "2rem",
                                       fontWeight: "700",
                                       minHeight: "20px",
                                     }}
                                   >
                                     ENVIRONMENTAL BENEFITS
                                   </Typography>
                                 </Card>
                                 <Typography
                                   className="textContent"
                                   variant="h5"
                                   component="h5"
                                   align="center"
                                   style={{ lineHeight: "45px" }}
                                 >
                                   You would reduce approximately {savedTonsCo2}{" "}
                                   tons of CO2 emissions equivalent annually.
                                   This is the same as planting approximately{" "}
                                   {plantedTree} trees over a 10 year period!
                                 </Typography>
                               </CardContent>
                             </Card>

                             <Grid>
                               <br />
                               <br />
                               <br />
                               <br />
                             </Grid>
                           </Container>
                           <Modal
                             aria-labelledby="simple-modal-title"
                             aria-describedby="simple-modal-description"
                             open={modalState}
                             //  onClose={handleCloseModal}
                             style={{
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                             }}
                           >
                             <Card style={{ maxWidth: "350px" }}>
                               <IonToolbar style={{ height: "80px" }}>
                                 <IonTitle
                                   size="small"
                                   className="ion-text-center"
                                   id="headerToolbar"
                                   style={{
                                     marginTop: "25px",
                                   }}
                                 >
                                   Fill Your Email to See the Results
                                 </IonTitle>
                                 <IonButtons
                                   slot="end"
                                   style={{
                                     marginTop: "-20px",
                                   }}
                                 >
                                   <IonButton onClick={handleClickCloseModal}>
                                     <IonIcon icon={closeOutline}></IonIcon>
                                   </IonButton>
                                 </IonButtons>
                               </IonToolbar>
                               <IonTitle
                                 size="small"
                                 className="modalSubText"
                                 id="headerToolbar"
                                 style={{
                                   marginTop: "10px",
                                   marginBottom: "10px",
                                   fontSize: "0.8rem",
                                   "font-weight": "300",
                                   "text-align": "center",
                                 }}
                               >
                                 We'll send you a Heat Pump Buyer Guideline
                               </IonTitle>
                               <CardContent className="noPaddingTop">
                                 <TextField
                                   required
                                   type="text"
                                   fullWidth
                                   id="NameFieldModal"
                                   label="Name"
                                   variant="outlined"
                                   value={nameFieldModal}
                                   onFocus=""
                                   onChange={handleChangeNameFieldModal}
                                   onBlur=""
                                   inputProps={{ minlength: 3 }}
                                   className="marginBottom"
                                 />

                                 <TextField
                                   required
                                   style={{ textAlign: "center" }}
                                   type="email"
                                   fullWidth
                                   id="EmailFieldModal"
                                   label="Email"
                                   variant="outlined"
                                   value={emailFieldModal}
                                   onFocus=""
                                   onChange={handleChangeEmailFieldModal}
                                   onBlur=""
                                   inputProps={{ minlength: 5 }}
                                 />
                                 <Typography
                                   className="centerItSeelf"
                                   variant="body2"
                                   component="h5"
                                   style={{
                                     height: "50px",
                                     color: "red",
                                     textAlign: "center",
                                   }}
                                 >
                                   {emailFieldValidator}
                                 </Typography>
                               </CardContent>
                               <CardActions className="centerItSeelf marginBottom">
                                 <IonButton
                                   disabled={modalButtonAbled}
                                   style={{
                                     backgroundColor: themeColor1,
                                     borderRadius: "100px",
                                   }}
                                   color="primary"
                                   shape="round"
                                   expand="full"
                                   size="large"
                                   fill="outline"
                                   onClick={HandleClickButtonModal}
                                 >
                                   Show Results
                                 </IonButton>
                               </CardActions>
                               <div
                                 style={{
                                   display: spinnerDisplay,
                                   position: "fixed",
                                   top: "50%",
                                   left: "50%",
                                   marginLeft: "-30px",
                                   marginTop: "-60px",
                                 }}
                               >
                                 <CircularProgress
                                   color="black"
                                   style={{
                                     height: "60px",
                                     width: "60px",
                                   }}
                                 />
                               </div>
                             </Card>
                           </Modal>
                           <Grid
                             xs={12}
                             style={{
                               display: "block",
                               textAlign: "center",
                               marginTop: "-60px",
                             }}
                           ></Grid>
                           <Typography
                             className="centerItSeelf"
                             variant="body2"
                             component="h5"
                             style={{
                               height: "20px",
                               display: cardDisplay1,
                               padding: "20px",
                               textAlign: "center",
                             }}
                           >
                             Share your results on Facebook and receive a FREE
                             Heat Pump Comparison Chart!
                           </Typography>
                           <Grid xs={12} style={{ textAlign: "center" }}>
                             <FacebookShareButton
                               url="https://www.heatpumpsavingcalculator.net/"
                               quote=""
                               className="FacebookButton"
                               onClick={facebookHandleClick}
                             >
                               <img
                                 src={FacebookIcon}
                                 alt="Facebook-Share-Button"
                                 style={{ height: "65px" }}
                               />
                             </FacebookShareButton>
                           </Grid>
                           <Grid style={{ height: "80px" }}></Grid>

                           <Modal
                             aria-labelledby="simple-modal-title"
                             aria-describedby="simple-modal-description"
                             open={helperModalYearlyBilling}
                             onClose={() => setHelperModalYearlyBilling(false)}
                             style={{
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                             }}
                           >
                             <Card
                               style={{
                                 position: "absolute",
                                 maxWidth: "400px",
                                 padding: "10px",
                                 borderRadius: "10px",
                                 marginRight: "10px",
                               }}
                             >
                               <div style={{ padding: "10px" }}>
                                 <Typography
                                   className="centerItSeelf"
                                   variant="body2"
                                   component="h5"
                                   style={{
                                     height: "20px",
                                     padding: "20px",
                                     paddingBottom: "40px",
                                     textAlign: "center",
                                   }}
                                 >
                                   Electrical Yearly Heating Cost can be
                                   estimated by using the lowest and highest
                                   monthly bill.
                                 </Typography>
                               </div>
                               <div style={{ display: "flex" }}>
                                 <TextField
                                   type="number"
                                   fullWidth
                                   id="LowestBillValue"
                                   label="Lowest Bill Value"
                                   variant="outlined"
                                   value={lowestBillValue}
                                   onChange={handleChangeLowestBill}
                                   onBlur={() => {
                                     if (
                                       parseFloat(lowestBillValue).toFixed(
                                         2
                                       ) !== lowestBillValue
                                     ) {
                                       setLowestBillValue(
                                         parseFloat(lowestBillValue).toFixed(2)
                                       );
                                     }
                                   }}
                                   InputProps={{
                                     startAdornment: (
                                       <InputAdornment position="start">
                                         $
                                       </InputAdornment>
                                     ),
                                   }}
                                   inputProps={{
                                     min: 10,
                                     max: 15000,
                                     step: 10,
                                   }}
                                   style={{
                                     padding: "0 2px",
                                   }}
                                 />

                                 <TextField
                                   type="number"
                                   fullWidth
                                   id="HighestBillValue"
                                   label="Highest Bill Value"
                                   variant="outlined"
                                   value={highestBillValue}
                                   onChange={handleChangeHighestBill}
                                   onBlur={() => {
                                     if (
                                       parseFloat(highestBillValue).toFixed(
                                         2
                                       ) !== highestBillValue
                                     ) {
                                       setHighestBillValue(
                                         parseFloat(highestBillValue).toFixed(2)
                                       );
                                     }
                                   }}
                                   InputProps={{
                                     startAdornment: (
                                       <InputAdornment position="start">
                                         $
                                       </InputAdornment>
                                     ),
                                   }}
                                   inputProps={{
                                     min: 100,
                                     max: 15000,
                                     step: 10,
                                   }}
                                   style={{
                                     padding: "0 2px",
                                   }}
                                 />
                               </div>
                               <div
                                 style={{
                                   display: "flex",
                                   padding: "10px 20px",
                                   paddingLeft: "0",
                                 }}
                               >
                                 <Typography
                                   //className="centerItSeelf"
                                   variant="body2"
                                   component="h5"
                                   style={{
                                     margin: "auto",
                                     padding: "20px",
                                     textAlign: "center",
                                     fontSize: "1.2rem",
                                     width: "50%",
                                   }}
                                 >
                                   $ {parseFloat(billValueAnswer).toFixed(2)}
                                 </Typography>
                                 <Button
                                   style={{ padding: "10px", margin: "auto" }}
                                   variant="contained"
                                   color="primary"
                                   onClick={() => {
                                     setTextFielType2("text");
                                     setYearlyHeatingCost(billValueAnswer);
                                     YearlyHeatingCostStringSetter(
                                       parseFloat(billValueAnswer).toFixed(2)
                                     );
                                     setHelperModalYearlyBilling(false);
                                   }}
                                 >
                                   Set Amount
                                 </Button>
                               </div>
                             </Card>
                           </Modal>

                           <IonFooter>
                             <IonToolbar
                               color="primary"
                               style={{ height: "35px" }}
                             >
                               <IonTitle id="footerTitle" size="small">
                                 Powered by{" "}
                                 <a
                                   style={{ color: "white" }}
                                   href="https://www.heatpumpsavingcalculator.net"
                                 >
                                   www.heatpumpsavingcalculator.net
                                 </a>
                               </IonTitle>
                             </IonToolbar>
                           </IonFooter>
                         </Container>
                       </Element>
                       {/* </IonContent> */}
                     </IonPage>
                   </>
                 );
               }

export default App;
